<template>
  <v-row>
    <v-col
        cols="12"
        sm="12"
        md="12"
    >
      <v-select
          v-if="!user.statistic_id"
          v-model="statistic_id"
          :items="statistics"
          item-value="id"
          item-text="name"
          label="Выберите отчет"
          @click="loadStatistic"
          @input="onChangeStatistic()"
          :loading="isLoading"
          clearable
      />
      <v-alert
          v-if="errorsMessages.statistic_id"
          dense
          outlined
          type="error"
      >
        {{ errorsMessages.statistic_id[0] }}
      </v-alert>
      <v-select
          v-if="!user.statistic_group_id && (statistic_id == 'canteens' || statistic_id == 'menu')"
          v-model="statistic_group_id"
          :items="statistic_groups"
          item-value="id"
          item-text="name"
          label="Выберите группировку"
          @click="loadStatisticGroups"
          :loading="isLoading"
          clearable
      />
      <v-alert
          v-if="errorsMessages.statistic_group_id"
          dense
          outlined
          type="error"
      >
        {{ errorsMessages.statistic_group_id[0] }}
      </v-alert>
      <v-row v-if="statistic_id == 'buffet' ">
        <v-col
            cols="12"
            lg="4"
        >
          <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormattedMomentjs"
                  clearable
                  label="С"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateFrom"
                @change="menuFrom = false"
                locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
            cols="12"
            lg="4"
        >
          <v-menu
              v-model="menuTo"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormattedDatefns"
                  clearable
                  label="До"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateTo"
                @change="menuTo = false"
                locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="statistic_id == 'bgp' || statistic_id == 'bgp_city'">
        <v-col
            cols="12"
            lg="4"
        >
          <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormattedMomentjs"
                  clearable
                  label="С"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dateFrom = null"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateFrom"
                @change="menuFrom = false"
                locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
            cols="12"
            lg="4"
        >
          <v-menu
              v-model="menuTo"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="computedDateFormattedDatefns"
                  clearable
                  label="До"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dateTo = null"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateTo"
                @change="menuTo = false"
                locale="ru-RU"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-select
          v-if="!user.city_id && (statistic_id == 'bgp')||!user.city_id && (statistic_id == 'buffet')||!user.city_id && (statistic_id == 'bgp_city')"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="isLoading"
          clearable
          label="Выберите город"
      />
      <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
      >
        {{ errorsMessages.city_id[0] }}
      </v-alert>
      <v-select
          v-if="!user.catering_id && (statistic_id == 'bgp')||!user.catering_id && (statistic_id == 'buffet')"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="isLoading"
          clearable
      />
      <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
      >
        {{ errorsMessages.catering_id[0] }}
      </v-alert>
      <v-select
          v-if="!user.canteen_id && (statistic_id == 'bgp')||!user.canteen_id && (statistic_id == 'buffet')"
          v-model="canteen_id"
          :items="canteens"
          item-value="id"
          item-text="name"
          @click="loadCanteens"
          :loading="isLoading"
          clearable
          label="Столовая"
      />
      <v-row>
        <v-col v-if="statistic_id != 'bgp_city'" cols="auto">
          <v-btn
              class="primary"
              small
              cols="12"
              lg="4"
              @click="viewStatistic()"
          >
            Cформировать
          </v-btn>
        </v-col>
        <v-col v-else cols="auto">
          <v-btn
              class="primary mr-2"
              small
              cols="12"
              lg="4"
              :disabled="isExcelLoading || !(!!dateFrom && !!dateTo && !!city_id)"
              @click="getDataByCity()">
            {{ isExcelLoading ? 'Загрузка...' : 'Сформировать' }} 
          </v-btn>
          <v-btn
            class="primary"
            small
            cols="12"
            lg="4"
            :disabled="isExcelLoading || groupedData.length <= 0"
            @click="exportToExcel()">
            Экспорт в Excel 
          </v-btn>
        </v-col>
     </v-row>
      <v-row>
        <v-col v-if="groupedData.length > 0" cols=12>
          <v-data-table 
            :headers="groupedHeaders" 
            :items="groupedData">
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="12"
    >
      <div id="viewer"></div>
    </v-col>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  {{ ecp_file }}
                  <input type="file" class="form-control" v-on:change="onFileChange">
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      label="ЭЦП пароль*"
                      hint="example of persistent helper text"
                      persistent-hint
                      required
                      v-model="ecp_password"
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
            <small>*Все поля обязательные</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Закрыть
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="completeBGP"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            :color="snackbarColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>

</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState, mapActions} from "vuex";
import "@/../public/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
import {format, parseISO} from "date-fns";
import Excelix from 'excelix-js'

export default {
  name: "Page",
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      statistic_id: undefined,
      statistics: [],
      statistic_group_id: undefined,
      statistic_groups: [],
      errorsMessages: {},
      isLoading: false,
      cities: [],
      city_id: '',
      ecp_file: '',
      ecp_password: '',
      caterings: [],
      catering_id: '',
      canteens: [],
      canteen_id: '',
      dateFrom: "",
      dateTo: "",
      menuFrom: false,
      dialog: false,
      menuTo: false,
      report: '',
      show_report: false,
      isExcelLoading: false,
      groupedData: [],
      groupedHeaders: [
        {
          value: "cateringName",
          text: "Организации"
        },
        {
          value: "cateringBIN",
          text: "БИН"
        },
        {
          value: "countBIN",
          text: "Кол-во учеников"
        },
        {
          value: "sumCount",
          text: "Кол-во БГП"
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      isProgress: (state) => state.isLoading,
    }),
    computedDateFormattedMomentjs() {
      return this.dateFrom ? format(parseISO(this.dateFrom), "dd-MM-yyyy") : "";
    },
    computedDateFormattedDatefns() {
      return this.dateTo ? format(parseISO(this.dateTo), "dd-MM-yyyy") : "";
    },
  },

  mounted() {
    /**
     * Подключаем скрипты и стили Stimulsoft
     */
        // let reportScript = document.createElement('script');
        // reportScript.setAttribute('src', '/stimulsoft/stimulsoft.reports.js');
        // document.head.appendChild(reportScript);
        //
        // let viewerScript = document.createElement('script');
        // viewerScript.setAttribute('src', '/stimulsoft/stimulsoft.viewer.js');
        // document.head.appendChild(viewerScript);

    let script1 = document.createElement('script');
    script1.setAttribute('src', '/scripts/stimulsoft.reports.engine.pack.js');
    document.head.appendChild(script1);

    let script2 = document.createElement('script');
    script2.setAttribute('src', '/scripts/stimulsoft.reports.export.pack.js');
    document.head.appendChild(script2);

    let script3 = document.createElement('script');
    script3.setAttribute('src', '/scripts/stimulsoft.viewer.pack.js');
    document.head.appendChild(script3);

  },

  methods: {
    ...mapActions(["updateIsLoading"]),
    completeBGP() {
      event.preventDefault();
      let app = this;
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      let formData = new FormData();
      formData.append('report', JSON.stringify(app.report));
      formData.append('ecp_file', app.ecp_file);
      formData.append('ecp_password', app.ecp_password);
      formData.append('dateFrom', app.dateFrom);
      formData.append('user_id', app.user.id);
      formData.append('dateTo', app.dateTo);
      formData.append('catering_id', app.catering_id);
      formData.append('type', 'bgp');
      formData.append('canteen_id', app.canteen_id);
      this.axios.post("/reports", formData, config).then((resp) => {
        if (resp.status === 200) {
          this.show_report = false;
          this.snackbar = true;
          this.snackbarColor = "green";
          this.snackbarText = "Успешно отправлено на согласование";
        }
      });
    },
    viewStatistic() {
      this.show_report = true;
      
      if (!this.statistic_id) {
        return;
      }
      if (this.statistic_id == 'canteens' || this.statistic_id == 'menu') {
        if (!this.statistic_group_id) {
          return;
        }
        this.axios('/statistics/' + this.statistic_id + '/file?grouping=' + this.statistic_group_id).then(({data: file}) => {
          window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmrayRDzjou2vJRbcybGu50js8WP/mVUveTqzDmYeqxBRbB0o" +
              "880UOsmpaZmvzDCfgb1iZJy0OWSZNj7g+J6ZLi08QjP6sctKP2D3Rtyd67jji+b4de9et/YcfGRfCT4uZwJ+WpXMrt" +
              "rOke9GSAqKx1RN79uDp0DUUKCL6tsBGrzD8PGssJwf48uRW3eQjVCIrJkC/JAPW8ar/hHiRZmuTfeWk87j9w3R8sI2" +
              "aSdPFrmzZ19TBs9TXUPJ9/zxLnLf7urI2CEAenAxXPQLrc3rirlIoscF9OFn+ZV44sYJP0bPzApYyY9uXMcOCFi6G6" +
              "DATI5Nou/6jGjiMTgobo/MHG4JUgAAEmx1oe/UmalCpa2i9X86QVRNpTiW6zu/Kj9thtXhlsJ4SURQeyHSb6COWSmc" +
              "JXP82BeRCbsRYmxxCpu17N5Yk07FVpiY3eH8oDY9G/yeFpOl3acZOIhYldCM5MBIQ2MucmVnWH3Ps17rEb/b/w8BgJ" +
              "YCNS4rh/HkbO6nG/2Ohzlbk4/4GM2p2IsmfJ";
          var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
          viewer.renderHtml('viewer');
          var report = new window.Stimulsoft.Report.StiReport();

          report.load(file);
          viewer.report = report;
        });
      } else if (this.statistic_id == 'buffet') {
        let _query = '/statistics-buffet/' + this.statistic_id + '/file?1=1';
        if (this.dateFrom) {
          _query += '&date1=' + this.dateFrom;
        }
        if (this.dateTo) {
          _query += '&date2=' + this.dateTo;
        }
        if (this.city_id) {
          _query += '&cityCode=' + this.city_id;
        }
        if (this.catering_id) {
          _query += '&cateringBIN=' + this.catering_id;
        }
        if (this.canteen_id) {
          _query += '&schoolBIN=' + this.canteen_id;
        }
        this.axios(_query).then(({data: file}) => {
          window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmrayRDzjou2vJRbcybGu50js8WP/mVUveTqzDmYeqxBRbB0o" +
              "880UOsmpaZmvzDCfgb1iZJy0OWSZNj7g+J6ZLi08QjP6sctKP2D3Rtyd67jji+b4de9et/YcfGRfCT4uZwJ+WpXMrt" +
              "rOke9GSAqKx1RN79uDp0DUUKCL6tsBGrzD8PGssJwf48uRW3eQjVCIrJkC/JAPW8ar/hHiRZmuTfeWk87j9w3R8sI2" +
              "aSdPFrmzZ19TBs9TXUPJ9/zxLnLf7urI2CEAenAxXPQLrc3rirlIoscF9OFn+ZV44sYJP0bPzApYyY9uXMcOCFi6G6" +
              "DATI5Nou/6jGjiMTgobo/MHG4JUgAAEmx1oe/UmalCpa2i9X86QVRNpTiW6zu/Kj9thtXhlsJ4SURQeyHSb6COWSmc" +
              "JXP82BeRCbsRYmxxCpu17N5Yk07FVpiY3eH8oDY9G/yeFpOl3acZOIhYldCM5MBIQ2MucmVnWH3Ps17rEb/b/w8BgJ" +
              "YCNS4rh/HkbO6nG/2Ohzlbk4/4GM2p2IsmfJ";
          var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

          viewer.renderHtml('viewer');
          var report = new window.Stimulsoft.Report.StiReport();
          this.report = file;
          report.load(file);
          viewer.report = report;
        });
      } else if (this.statistic_id == 'bgp') {
        let _query = '/statistics/' + this.statistic_id + '/file?1=1';
        if (this.dateFrom) {
          _query += '&date1=' + this.dateFrom;
        }
        if (this.dateTo) {
          _query += '&date2=' + this.dateTo;
        }
        if (this.city_id) {
          _query += '&cityCode=' + this.city_id;
        }
        if (this.catering_id) {
          _query += '&cateringBIN=' + this.catering_id;
        }
        if (this.canteen_id) {
          _query += '&schoolBIN=' + this.canteen_id;
        }
        this.axios(_query).then(({data: file}) => {
          window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmrayRDzjou2vJRbcybGu50js8WP/mVUveTqzDmYeqxBRbB0o" +
              "880UOsmpaZmvzDCfgb1iZJy0OWSZNj7g+J6ZLi08QjP6sctKP2D3Rtyd67jji+b4de9et/YcfGRfCT4uZwJ+WpXMrt" +
              "rOke9GSAqKx1RN79uDp0DUUKCL6tsBGrzD8PGssJwf48uRW3eQjVCIrJkC/JAPW8ar/hHiRZmuTfeWk87j9w3R8sI2" +
              "aSdPFrmzZ19TBs9TXUPJ9/zxLnLf7urI2CEAenAxXPQLrc3rirlIoscF9OFn+ZV44sYJP0bPzApYyY9uXMcOCFi6G6" +
              "DATI5Nou/6jGjiMTgobo/MHG4JUgAAEmx1oe/UmalCpa2i9X86QVRNpTiW6zu/Kj9thtXhlsJ4SURQeyHSb6COWSmc" +
              "JXP82BeRCbsRYmxxCpu17N5Yk07FVpiY3eH8oDY9G/yeFpOl3acZOIhYldCM5MBIQ2MucmVnWH3Ps17rEb/b/w8BgJ" +
              "YCNS4rh/HkbO6nG/2Ohzlbk4/4GM2p2IsmfJ";
          var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

          viewer.renderHtml('viewer');
          var report = new window.Stimulsoft.Report.StiReport();
          //file.Dictionary.Variables[0].Value = 'https://sm.tolem.asia/api/free-school-meals.php' + _query
          this.report = file;

          report.load(file);
          viewer.report = report;

        });
      }
    },
    onFileChange(event) {
      this.ecp_file = event.target.files[0];
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    loadStatistic() {
      this.errorsMessages.statistic_id = null;
      this.isLoading = true;
      this.statistics = [
        {id: 'canteens', name: 'Отчет по столовым'},
        {id: 'menu', name: 'Отчет по меню'},
        {id: 'bgp', name: 'Отчет по БГП'},
        {id: 'buffet', name: 'Отчёт по продаже буфетной продукции'},
        {id: 'bgp_city', name: 'Отчет БГП по городам'}
      ];
      this.isLoading = false;
    },
    onChangeStatistic() {
      this.groupedData = []
    },
    loadStatisticGroups() {
      this.isLoading = true;
      if (this.statistic_id || this.user.statistic_id) {
        if (this.statistic_id == 'canteens') {
          this.statistic_groups = [{id: 'city', name: 'Группировка по городу'}, {
            id: 'cartering',
            name: 'Группировка по компании'
          }];
        } else if (this.statistic_id == 'menu') {

          this.statistic_groups = [{id: 'city', name: 'Группировка по городу'}, {
            id: 'cartering',
            name: 'Группировка по компании'
          }];

        }
      } else {
        this.errorsMessages.statistic_id = ["Выберите отчет"];
      }

      this.isLoading = false;
    },
    loadCities() {
      this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.errorsMessages.catering_id = null;
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
    },
    loadCanteens() {
      this.isLoading = true;
      let queryString = "/get-canteens-list-by-own-catering";
      this.errorsMessages.canteen_id = null;
      if (this.catering_id) {
        queryString += `?catering_id=${this.catering_id}`;
      }

      if (this.catering_id || this.user.catering_id) {
        this.errorsMessages.catering_id = null;
        this.axios(queryString).then((response) => {
          this.canteens = response.data.data.canteens;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.canteen_id = ["Поле canteen_id"];
      }
    },
    getDataByCity() {
      this.isExcelLoading = true
      const url = 'https://sm.tolem.asia/api/free-school-meals.php?'
      let _query = url 

      if (this.dateFrom) {
        _query += '&date1=' + this.dateFrom;
      }
      if (this.dateTo) {
        _query += '&date2=' + this.dateTo;
      }
      if (this.city_id) {
        _query += '&cityCode=' + this.city_id;
      }
      if (this.catering_id) {
        _query += '&cateringBIN=' + this.catering_id;
      }
      if (this.canteen_id) {
        _query += '&schoolBIN=' + this.canteen_id;
      }

      this.axios.get(_query).then(r => {
        this.groupedData = this.getGroupedData(r.data)
      }).catch(e => {
        this.groupedData = []
        console.error(e)
      }).finally(() => {
        this.isExcelLoading = false
      })
    },
    exportToExcel() {
      const fields = {
        "cateringName": { total: false, text: "Организации" },
        "cateringBIN": { total: false, text: "БИН" },
        "countBIN": { total: true, text: "Кол-во учеников" },
        "sumCount": { total: true, text: "Кол-во БГП" },
        "sumPrice": { total: true, text: "Сумма" },
      }

      const ex = new Excelix(fields)
      ex.addRow([`Транзакции с ${this.dateFrom} по ${this.dateTo}`],
        { bold: true })
      ex.addRow(['Город:', this.groupedData[0].cityName], { bold: true })
      ex.addRow([])
      ex.addJson(this.groupedData)

      const filename = `${this.groupedData[0].cityName}-${this.dateFrom}-${this.dateTo}.xlsx`
      ex.writeToFile(filename)
   },

   getGroupedData(data) {
      let result = data.reduce((acc, item) => {
        let bin = item.cateringBIN;
        if (!acc[bin]) {
          acc[bin] = {
            cityCode: item.cityCode,
            cityName: item.cityName,
            cateringBIN: item.cateringBIN,
            cateringName: item.cateringName,
            countBIN: 0,
            sumCount: 0,
            sumPrice: 0,
          };
        }
        acc[bin].countBIN++;
        acc[bin].sumCount += typeof item.count === 'number' 
                              ? item.count : 0;
        acc[bin].sumPrice += (typeof item.count === 'number' && 
                              typeof item.price === 'number') 
                              ? item.count * item.price : 0;
        return acc;
      }, {});

      result = Object.values(result);
      return result
    }, 
  },
  watch: {
    page: {
      handler() {

      },
    },
  },
};
</script>

<style scoped>
.filter-name {
  margin-top: 20px;
}
</style>

<template>
  <v-row>
    <v-col
        cols="8"
        sm="8"
        md="8"
    >
      <div id="viewer"></div>
    </v-col>
    <v-col
        cols="4"
        sm="4"
        md="4"
    >
      <div class="d-flex" v-if="report.status==0">
        <button v-on:click="changeStatus(1)" class="qbtn__green">Утвердить</button>
        <button v-on:click="changeStatus(2)" class="qbtn__red">Отклонить</button>
      </div>

    </v-col>
  </v-row>

</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState} from "vuex";
import "@/../public/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
import {format, parseISO} from "date-fns";

export default {
  name: "Page",
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      statistic_id: undefined,
      statistics: [],
      statistic_group_id: undefined,
      statistic_groups: [],
      errorsMessages: {},
      isLoading: false,
      cities: [],
      city_id: '',
      caterings: [],
      catering_id: '',
      canteens: [],
      canteen_id: '',
      dateFrom: "",
      dateTo: "",
      menuFrom: false,
      menuTo: false,
      report: '',
      show_report: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      isProgress: (state) => state.isLoading,
    }),
    computedDateFormattedMomentjs() {
      return this.dateFrom ? format(parseISO(this.dateFrom), "dd-MM-yyyy") : "";
    },
    computedDateFormattedDatefns() {
      return this.dateTo ? format(parseISO(this.dateTo), "dd-MM-yyyy") : "";
    },
  },

  mounted() {
    this.loadReport();
    let script1 = document.createElement('script');
    script1.setAttribute('src', '/scripts/stimulsoft.reports.engine.pack.js');
    document.head.appendChild(script1);

    let script2 = document.createElement('script');
    script2.setAttribute('src', '/scripts/stimulsoft.reports.export.pack.js');
    document.head.appendChild(script2);

    let script3 = document.createElement('script');
    script3.setAttribute('src', '/scripts/stimulsoft.viewer.pack.js');
    document.head.appendChild(script3);
  },
  methods: {
    changeStatus(status) {
      this.axios.post('/reports/' + this.$route.params.id + '/status', {'status': status}).then(({data: response}) => {
        this.report = response;
      });
    },
    loadReport() {
      this.axios('/reports/' + this.$route.params.id).then(({data: file}) => {
        window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmrayRDzjou2vJRbcybGu50js8WP/mVUveTqzDmYeqxBRbB0o" +
            "880UOsmpaZmvzDCfgb1iZJy0OWSZNj7g+J6ZLi08QjP6sctKP2D3Rtyd67jji+b4de9et/YcfGRfCT4uZwJ+WpXMrt" +
            "rOke9GSAqKx1RN79uDp0DUUKCL6tsBGrzD8PGssJwf48uRW3eQjVCIrJkC/JAPW8ar/hHiRZmuTfeWk87j9w3R8sI2" +
            "aSdPFrmzZ19TBs9TXUPJ9/zxLnLf7urI2CEAenAxXPQLrc3rirlIoscF9OFn+ZV44sYJP0bPzApYyY9uXMcOCFi6G6" +
            "DATI5Nou/6jGjiMTgobo/MHG4JUgAAEmx1oe/UmalCpa2i9X86QVRNpTiW6zu/Kj9thtXhlsJ4SURQeyHSb6COWSmc" +
            "JXP82BeRCbsRYmxxCpu17N5Yk07FVpiY3eH8oDY9G/yeFpOl3acZOIhYldCM5MBIQ2MucmVnWH3Ps17rEb/b/w8BgJ" +
            "YCNS4rh/HkbO6nG/2Ohzlbk4/4GM2p2IsmfJ";
        var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
        viewer.renderHtml('viewer');
        var report = new window.Stimulsoft.Report.StiReport();
        this.report = file;
        report.load(JSON.parse(this.report.report));
        viewer.report = report;
      });
    }
  },
  watch: {},
};
</script>

<style scoped>
.filter-name {
  margin-top: 20px;
}

.v-btn__danger {
  background: red;
}

.qbtn__green {
  background: #4caf50;
  padding: 5px 15px;
  border: none;
  border-radius: 15px;
  color: #fff;
  margin-right: 10px;
}

.qbtn__red {
  background: #ff5252;
  padding: 5px 15px;
  border: none;
  border-radius: 15px;
  color: #fff;
  margin-right: 10px;
}
</style>

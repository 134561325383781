<template>
  <v-form
      ref="form"
      lazy-validation
      autocomplete="off"
  >
    <v-text-field
        v-model.trim="newUser.login"
        label="Логин"
        required
        :rules='[(v) => !!v || "Поле \"Логин\"  обязательно"]'
        ref="login"
        type="text"
        :name="`_${Math.random()}`"
        autocomplete="username"
    />
    <v-alert
        v-if="errorsMessages.login"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.login[0] }}
    </v-alert>

    <v-text-field
        v-model.trim="newUser.password"
        label="Введите пароль"
        type="password"
        required
        :rules="passwordRules"
        ref="password"
        autocomplete="new-password"
        :name="
      `_${Math.random()}`"
    />
    <v-alert
        v-if="errorsMessages.password"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.password[0] }}
    </v-alert>

    <v-text-field
        v-model.trim="newUser.password_confirmation"
        label="Повторите пароль"
        type="password"
        :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
    />
    <v-alert
        v-if="errorsMessages.password"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.password[0] }}
    </v-alert>
    <v-text-field
        v-model="maskedPhoneNumber"
        label="Телефон"
        :rules='[(v) => !!v || "Поле \"Телефон\"  обязательно"]'
        v-mask="'+#(###)-###-##-##'"
    />
    <v-alert
        v-if="errorsMessages.phone"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.phone[0] }}
    </v-alert>
    <v-text-field
        v-model.trim="newUser.first_name"
        label="Имя"
        :rules='[(v) => !!v || "Поле \"Имя\"  обязательно"]'
    />
    <v-alert
        v-if="errorsMessages.first_name"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.first_name[0] }}
    </v-alert>
    <v-text-field
        v-model.trim="newUser.last_name"
        label="Фамилия"
        :rules='[(v) => !!v || "Поле \"Фамилия\"  обязательно"]'
    />
    <v-alert
        v-if="errorsMessages.last_name"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.last_name[0] }}
    </v-alert>
    <v-text-field
        v-model.trim="newUser.email"
        label="Email"
    />
    <v-alert
        v-if="errorsMessages.email"
        dense
        outlined
        type="error"
    >
      {{ errorsMessages.email[0] }}
    </v-alert>
    <v-select
        v-model="selectedCityId"
        :items="cities"
        item-text="name"
        item-value="id"
        label="Выберите город"
        @click="startSelect()"
        @input="setSelectedCity"
        clearable
    />
    <v-select
        v-model="selectedCateringId"
        :items="caterings"
        item-text="name"
        item-value="id"
        label="Выберите организацию"
        @input="setSelectedCatering"
        clearable
    />
    <v-select
        v-model="selectedCanteenId"
        :items="canteens"
        item-text="name"
        item-value="id"
        label="Выберите столовую"
        @input="setSelectedCanteen"
        clearable
    />

    <v-btn
        @click="save"
        class="ma-2"
        outlined
        color="indigo"
    >
      Сохранить
    </v-btn>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            :color="snackbarColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>

</template>

<script>
import FormMixin from "@/mixins/form-mixin.js";
// import { jsonToFormData } from "@/utils";

export default {
  name: "Form",
  mixins: [FormMixin],
  props: {
    userFromProp: {
      type: Object,
    },
  },
  data() {
    return {
      passwordRules: [
        (v) =>
            v?.length >= 7 ||
            'Поле " Пароль " должно быть не меньше семи символов',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Поле " Повторить пароль " обязательно ',
      ],
      maskedPhoneNumber: null,
      newUser: {
        first_name: "",
        last_name: "",
        login: "",
        password: "",
        password_confirmation: "",
        phone: null,
        email: null,
        city_id: this.selectedCityId,
        catering_id: this.selectedCateringId,
        canteen_id: this.selectedCanteenId,
        patronymic: null,
      },
      caterings: [],
      canteens: [],
      cities: [],
      selectedCityId: null,
      selectedCateringId: null,
      selectedCanteenId: null,
      errorsMessages: {
        login: null,
        password: null,
        phone: null,
        email: null,
        first_name: null,
        last_name: null,
      },
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
          this.newUser.password === this.newUser.password_confirmation ||
          "Пароли должны совпадать";
    },
    clonedNewUser() {
      return JSON.parse(JSON.stringify(this.newUser));
    },
  },
  created() {
    this.getAllCites();
    if (this.userFromProp !== null) {
      this.newUser = Object.assign({}, this.userFromProp);
    }
  },
  mounted() {
    // const vLabelLogin = this.$refs.login.$el.querySelector(".v-label");
    // const vLabelPassword = this.$refs.password.$el.querySelector(".v-label");
    // vLabelLogin.classList.add("v-label--active");
    // vLabelPassword.classList.add("v-label--active");
    // console.log((this.$refs.login.$el.querySelector("input").value = "23"));
  },

  methods: {
    setSelectedCity(value) {
      console.log(value);
      this.getAllCanteens();
      this.getAllCanteens();
    },
    setSelectedCatering(value) {
      console.log(value);
      this.getAllCanteens();
      this.getAllCanteens();
    },
    setSelectedCanteen(value) {
      console.log(value);
      this.getAllCanteens();
      this.getAllCanteens();
    },
    startSelect() {
      this.getAllCites();
    },
    getAllCaterings() {
      const vm = this;
      this.axios
          .get(`/caterings?city_id=${this.selectedCityId}`)
          .then((resp) => {
            vm.caterings = resp.data.data.caterings;
            vm.totalPage = resp.data.data.caterings.total;
          });
    },
    getAllCanteens() {
      const vm = this;
      this.axios
          .get(`/canteens?city_id=${this.selectedCityId}`)
          .then((resp) => {
            vm.canteens = resp.data.data.canteens;
            vm.canteens = this.canteens.filter((item) => item.catering_id == this.selectedCateringId)
            vm.totalPage = resp.data.data.canteens.total;
          });
    },
    getAllCites() {
      var vm = this;
      this.axios.get(`/cities`).then(function (resp) {
        vm.cities = resp.data.data.cities;
        vm.totalPage = resp.data.data.cities.total;
      });
    },
    save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.newUser.city_id = this.selectedCityId;
        this.newUser.catering_id = this.selectedCateringId;
        this.newUser.canteen_id = this.selectedCanteenId;
        const vm = this;
        this.axios
            .post("/users", this.newUser)
            .then((resp) => {
              if (resp.status === 200) {
                vm.snackbar = true;
                vm.snackbarColor = "green";
                vm.snackbarText = resp.data.status;
                this.$refs.form.reset();
                this.$router.push({name: "Users"});
              }
            })
            .catch(({response}) => {
              console.log(response);
              if (response.status === 422) {
                vm.snackbar = true;
                vm.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
                this.errorsMessages = response.data.errors;
              } else {
                vm.snackbar = true;
                vm.snackbarText = response.data.messages;
              }
            });
      }
    },
  },

  watch: {
    selectedCityId: {
      handler(val) {
        if (val) {
          this.getAllCaterings();
          this.getAllCanteens();
        }
      },
    },
    maskedPhoneNumber: {
      handler() {
        this.newUser.phone =
            "+" + this.maskedPhoneNumber.replace(/^(\+)|\D/g, "");
        this.errorsMessages.phone = null;
      },
    },
    clonedNewUser: {
      deep: true,
      handler(nUser, oUser) {
        console.log(nUser.login, oUser.login);
        if (nUser.login !== oUser.login) {
          this.errorsMessages.login = null;
        }
        if (nUser.password !== oUser.password) {
          this.errorsMessages.password = null;
        }
        if (nUser.password_confirmation !== oUser.password_confirmation) {
          this.errorsMessages.password = null;
        }
        if (nUser.email !== oUser.email) {
          this.errorsMessages.email = null;
        }
        if (nUser.first_name !== oUser.first_name) {
          this.errorsMessages.first_name = null;
        }
        if (nUser.last_name !== oUser.last_name) {
          this.errorsMessages.last_name = null;
        }
      },
    },
  },
};
</script>

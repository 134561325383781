<template>
  <v-row>
    <v-col>
      <h2>Добавить блюдо</h2>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-select
          v-if="!user.city_id"
          v-model="city_id"
          :items="cities"
          item-value="id"
          item-text="name"
          @click="loadCities"
          :loading="isLoading"
          clearable
          label="Выберите город"
          :rules='[(v) => !!v || "Поле \"Город\"  обязательно"]'
        />
        <v-alert
          v-if="errorsMessages.city_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.city_id[0]}}
        </v-alert>
        <v-select
          v-if="!user.catering_id"
          v-model="catering_id"
          :items="caterings"
          item-value="id"
          item-text="name"
          :rules='[(v) => !!v || "Поле \"Организация\"  обязательно"]'
          label="Выберите организацию"
          @click="loadCaterings"
          :loading="isLoading"
          clearable
        />
        <v-alert
          v-if="errorsMessages.catering_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.catering_id[0]}}
        </v-alert>
        <v-autocomplete
          v-model="selectedArticle"
          :loading="loading"
          :items="dishes"
          :search-input.sync="search"
          item-text="fullName"
          item-value="id"
          label="Введите артикул*"
          return-object
          :rules='[(v) => !!v || "Поле \"Артикул\"  обязательно"]'
          @input="inputDishArticle"
          clearable
        ></v-autocomplete>

        <v-alert
          v-if="errorsMessages.dish_id"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.dish_id[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="dishOwn.name"
          label="Название*"
          :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
          @input="inputName"
        />
        <v-alert
          v-if="errorsMessages.name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.name[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="dishOwn.price_cash"
          label="Цена наличными"
          required
          :rules="isNumber"
          @input="inputPriceCash"
          :loading="loading"
        />
        <v-alert
          v-if="errorsMessages.name"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.price_cash[0]}}
        </v-alert>
        <v-text-field
          v-model.trim="dishOwn.price_cashless"
          label="Цена безналичная"
          @input="inputPriceCashless"
          :rules="isNumber"
          :loading="loading"
        />
        <v-alert
          v-if="errorsMessages.price_cashless"
          dense
          outlined
          type="error"
        >
          {{ errorsMessages.price_cashless[0]}}
        </v-alert>
        <v-img
          alt=""
          :src="dishImagePreview"
          max-width="400px"
          :loading="loading"
        />
        <div
          class="file-dragable"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <v-file-input
            truncate-length="15"
            label="Выберите фото или перетащите"
            accept="image/*"
            v-model="uploadFile"
            ref="file"
            prepend-icon="mdi-camera"
            @change="showPreviewDishImage"
          ></v-file-input>
          <v-alert
            v-if="errorsMessages.image"
            dense
            outlined
            type="error"
          >
            {{ errorsMessages.image[0]}}
          </v-alert>

        </div>
        <v-select
          v-model="dishOwn.complex_category_code"
          label="Код категории комплексного меню"
          :items="complexCategories"
          item-value="code"
          item-text="name"
          clearable
        />
        <v-textarea
          v-model.trim="dishOwn.description"
          label="Описание"
          required
        />
        <v-textarea
          v-model.trim="dishOwn.other_info"
          label="Дополнительная информация"
          :loading="loading"
        />
        <v-snackbar v-model="snackbar">
          {{snackbarText}}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
          @click="save"
          class="ma-2"
          outlined
          color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>
</template>

<script>
import FormMixin from "@/mixins/form-mixin";
import { mapState } from "vuex";

export default {
  mixins: [FormMixin],
  data() {
    return {
      dish: null,
      dishOwn: {
        dish_id: null,
        name: null,
        price_cash: null,
        price_cashless: null,
        other_info: "",
        complex_category_code: null,
        catering_id: this.user?.catering_id
          ? this.user?.catering_id
          : this.catering_id,
        image_path: null,
        image: null,
        article: this.getSelectedArticle,
        description: null,
      },
      errorsMessages: {
        dish_id: null,
        name: null,
        price_cash: null,
        price_cashless: null,
        catering_id: null,
        city_id: null,
      },
      complexCategories: [],
      dishes: [],
      loading: false,
      search: null,
      selectedArticle: {
        id: null,
        fullName: null,
        name: null,
        article: null,
      },
      customArticle: "",
      cities: [],
      city_id: null,
      caterings: [],
      catering_id: null,
      isLoading: false,
      isExistOwnDish: false,
      uploadFile: null,
      dishImagePreview: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    getSelectedArticle() {
      return this.selectedArticle.article;
    },
  },
  created() {
    this.getAllComplexCategories();
    console.log(this.user);
  },
  methods: {
    showPreviewDishImage() {
      console.log(this.uploadFile);
      if (this.uploadFile) {
        this.dishImagePreview = URL.createObjectURL(this.uploadFile);
      }
    },
    onChange() {
      console.log(this.$refs.file.files);
      this.uploadFile = this.$refs.file.files[0];

      console.log("CHANGE");
      // console.log(this.uploadFile );
      // this.dishOwn.image_path = this.uploadFile;
      this.errorsMessages.image = null;
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {},
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    loadCities() {
      this.errorsMessages.city_id = null;
      this.isLoading = true;
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
        this.isLoading = false;
      });
    },

    loadCaterings() {
      this.isLoading = true;
      let queryString = "/caterings";
      this.dishes = [];
      this.errorsMessages.catering_id = null;
      //console.log(this.city_id);
      if (this.city_id) {
        this.caterings = [];
        queryString += `?city_id=${this.city_id}`;
      }
      if (this.user.city_id) {
        queryString += `?city_id=${this.user.city_id}`;
      }
      if (this.city_id || this.user.city_id) {
        this.axios(queryString).then((responseCaterings) => {
          this.caterings = responseCaterings.data.data.caterings;
          this.isLoading = false;
        });
      } else {
        this.errorsMessages.city_id = ["Поле city_id"];
      }
      console.log(queryString);
    },
    inputName() {
      this.errorsMessages.name = null;
    },
    inputDishArticle() {
      this.errorsMessages.dish_id = null;
    },
    inputPriceCash() {
      this.errorsMessages.price_cash = null;
    },
    inputPriceCashless() {
      this.errorsMessages.price_cashless = null;
    },
    getAllComplexCategories() {
      this.axios("/dic-complex-categories").then((resp) => {
        this.complexCategories = resp.data.data.dic_complex_categories;
      });
    },
    getFormData() {
      const formData = new FormData();
      if (this.uploadFile) {
        formData.append("image", this.uploadFile);
      }
      if (this.catering_id) {
        formData.append("catering_id", this.catering_id);
      }
      if (this.city_id) {
        formData.append("city_id", this.city_id);
      }

      for (const [key, value] of Object.entries(this.dishOwn)) {
        if (key !== "image" && this.dishOwn[key] !== null) {
          formData.append(key, value);
        }
      }
      if (this.selectedArticle?.article) {
        formData.append("article", this.selectedArticle.article);
      }
      return formData;
    },
    save() {
      this.valid = this.$refs.form.validate();
      const params = this.getFormData();

      if (this.valid) {
        this.axios
          .post("/store-dishes-for-own-catering", params)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Успешно добавлено";
            this.$refs.form.reset();
          })
          .catch(({ response }) => {
            if (response.status === 422) {
              this.snackbar = true;
              this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
              this.errorsMessages = response.data.errors;
            } else {
              this.snackbar = true;
              this.snackbarText = response.data.data.message;
            }
          });
      }
    },
    getDishesByArticle(query) {
      this.loading = true;
      let queryString = `/get-dishes-by-article?article=${query}`;

      if (this.city_id) {
        queryString += `&city_id=${this.city_id}`;
      }

      this.axios
        .get(queryString)
        .then((resp) => {
          this.loading = false;
          this.dishes = resp.data.data.dishes.map((item) => {
            item.fullName = `${item.article} ${item.name}`;
            return item;
          });

          if (this.dishes.length === 0) {
            this.dishes.push({
              fullName: this.search,
              name: null,
              article: this.search,
            });
          }
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.snackbar = true;
            this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
            this.errorsMessages = response.data.errors;
          } else {
            this.snackbar = true;
            this.snackbarText = response.data.data.message;
          }
        });
    },
    mapDishToDishOwn(dish) {
      this.dishImagePreview = dish.image;
      this.dishOwn = {
        name: dish.name,
        article: dish.article,
        price_cash: dish.price_cash !== "null" ? dish.price_cash : 0,
        price_cashless:
          dish.price_cashless !== "null" ? dish.price_cashless : 0,
        other_info: dish.other_info !== "null" ? dish.other_info : "",
        complex_category_code: dish.complex_category_code,
        catering_id: dish.catering_id,
        image_path: dish.image,
        description: dish.description !== "null" ? dish.description : "",
        // ...dish,
      };
    },
    getDishById() {
      let queryString = `/dishes/${this.selectedArticle?.id}`;
      this.axios
        .get(queryString)
        .then((resp) => {
          this.loading = true;
          console.log("RESP", resp);
          // this.isExistDish();
          this.loading = false;
          if (!this.isExistOwnDish) {
            this.mapDishToDishOwn(resp.data.data.dish);
            // this.dishOwn = resp.data.data.dish;
          }
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.snackbar = true;
            this.snackbarText = "Данные заполненые неправильно";
            this.errorsMessages = response.data.errors;
          } else {
            this.snackbar = true;
            this.snackbarText = response.data.data.message;
          }
        });
    },
    isExistDish(cb) {
      const cateringId = this.user.catering_id
        ? this.user.catering_id
        : this.catering_id;
      if (cateringId) {
        this.axios
          .get(
            `/check-dish-for-existence-in-own-catering?article=${this.selectedArticle?.article}&catering_id=${cateringId}`
          )
          .then((resp) => {
            this.isExistOwnDish = resp.data.data.exists;
            if (!resp.data.data.exists) {
              cb();
            } else {
              throw new Error("exists");
            }
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "Уже добавлено";
          });
      }
    },
  },
  watch: {
    city_id: {
      handler(id) {
        this.caterings = [];
        if (id) {
          this.loadCaterings();
        }
      },
    },
    catering_id: {
      handler() {
        this.errorsMessages.catering_id = null;
      },
    },
    selectedArticle: {
      handler() {
        this.dishOwn = {};
        this.dishImagePreview = "";
        // this.dishOwn.article = this.selectedArticle.article;
        if (this.selectedArticle?.id) {
          this.isExistDish(this.getDishById);
        }
      },
    },
    search(val) {
      if (!this.catering_id && val) {
        this.errorsMessages.catering_id = ["Выберите организацию"];
      } else if (val && this.selectedArticle?.fullName !== val) {
        this.getDishesByArticle(val);
      }
    },
  },
};
</script>

<template>
  <div class="index-page">
    <div class="col-md-12 mt-5 pt-5">
      <div>
        <v-simple-table height="300px">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                №
              </th>
              <th class="text-left">
                Пользователь
              </th>
              <th class="text-left">
                Дата
              </th>
              <th class="text-left">
                Дата
              </th>
              <th class="text-left">
                ТИП
              </th>
              <th class="text-left">
                Столовая
              </th>
              <th class="text-left">
                Организация
              </th>
              <th class="text-left">
                Статус
              </th>
              <th class="text-left">
                Детали
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item,index) in statistics.data"
                :key="index"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.user != null ? item.user.first_name + " " + item.user.last_name : '' }}</td>
              <td>{{ item.dateFrom }}</td>
              <td>{{ item.dateTo }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.catering != null ? item.catering.name : '' }}</td>
              <td>{{ item.canteen != null ? item.canteen.name : '' }}</td>
              <td>{{ item.status_label }}</td>
              <td>
                <router-link :to="'/admin/my-reports/'+item.id" class="btn v-btn v-btn--active">Детали</router-link>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center">
          <Pagination :data="orders" @pagination-change-page="getResults" :limit="3" show-disabled/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState} from "vuex";
import LaravelVuePagination from 'laravel-vue-pagination';

export default {
  name: "Index",
  mixins: [PageMixins],
  data() {
    return {
      statistics: '',
      school: '',
      schools: '',
      child_q: '',
    }
  },
  components: {
    'Pagination': LaravelVuePagination
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    this.getResults(1);
  },
  methods: {
    getResults(page = 1) {
      let params = {
        'q': this.child_q,
        'page': page,
      };
      this.axios.get('/reports/my', {params: params})
          .then(response => {
            this.statistics = response.data
          });
    }
  },
};
</script>

<template>
  <div class="index-page">
    <div class="col-md-12">
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
              :color="snackbarColor"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <div class="row d-flex align-center mt-4">
        <div class="col-md-4">
          <div>
            <v-checkbox
                v-model="filter.only_iin"
                label="Искать только по ИИН"
                value="1"
            ></v-checkbox>
          </div>
          <template v-if="!filter.only_iin">
            <div>
              <label>Выберите школу</label>
              <select v-model="filter.school" class="q__select" placeholder="Выберите школу">
                <option value="">Выберите школу</option>
                <option v-for="item,index in all_schools" :key="index" :value="item.school">{{ item.school }}</option>
              </select>
            </div>
            <div v-if="all_schools[filter.school]">
              <label>Выберите Класс</label>
              <select v-model="filter.class" class="q__select" placeholder="Выберите класс"
              >
                <option value="">Выберите класс</option>
                <option v-for="item,index in all_schools[filter.school].class" :key="index" :value="item">{{
                    item
                  }}
                </option>
              </select>
            </div>
          </template>
          <v-text-field
              v-model="filter.iin"
              label="Введите ИИН"
              required
          ></v-text-field>
        </div>
        <div class="col-md-1">
          <v-btn v-on:click="getResults"
          >Поиск
          </v-btn>
        </div>
        <div class="col-md-7 ">
          <div class="row d-flex justify-lg-end mb-2 pb-2">
            <v-btn v-on:click="toggleImport=!toggleImport">Импортировать эксель</v-btn>
            <a style="margin-left: 20px;" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default"
               :href="'https://sm-api.tolem.asia/api/check-pupil-by-iin/export?filter='+JSON.stringify(this.filter)">Экспорт</a>
          </div>
          <div class="row d-flex" v-if="toggleImport">
            <v-text-field
                v-model="school_name"
                label="Школа"
                required
            ></v-text-field>
            <v-text-field
                v-model="school_class"
                label="Класс"
                required
            ></v-text-field>
            <input
                type="file"
                id="myFile"
                class="form-control"
                @change="onFileChange"
            >
            <v-btn v-on:click="uploadFile"
            >Сохранить
            </v-btn>
          </div>
        </div>
      </div>
      <div>
        <template v-if="filter.only_iin==0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  №
                </th>
                <th class="text-left">
                  ИИН
                </th>
                <th class="text-left">
                  ФИО
                </th>
                <th class="text-left">
                  Школа
                </th>
                <th class="text-left">
                  Класс
                </th>
                <th class="text-left">
                  Пол
                </th>
                <th class="text-left">
                  Дата рождение
                </th>
                <th class="text-left">
                  Тип
                </th>
                <th class="text-left">
                  Телефон
                </th>
                <th class="text-left">
                  Ответ
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in cards.data"
                  :key="index"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.iin }}</td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.school }}</td>
                <td>{{ item.class }}</td>
                <td>{{ item.sex }}</td>
                <td>{{ item.birthday }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  <template v-if="item.response!=null&&item.response!=''&&item.response!='undefined'">
                    <span v-html="parseData(item.response)"></span>
                  </template>
                  <template v-else="" style="text-align: center">
                    <div style="text-align: center">
                      <span style="margin:10px 0px;"> Ждем ответа</span><br>
                      <v-btn v-on:click="checkData(index,item)" size="small" class="mt-2"
                      >Запросить ответ
                      </v-btn>
                    </div>
                  </template>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <Pagination :data="cards" @pagination-change-page="getResults" :limit="3" show-disabled/>
          </div>
        </template>
        <template v-else="">
          <div>
            <div v-if="Object.keys(response_result).length>=1">
              <v-simple-table height="300px">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Есть ли карты</td>
                    <td>{{ response_result.hasCards ? 'Да' : 'Нет' }}</td>
                  </tr>
                  <tr>
                    <td>Привязана ли к МП</td>
                    <td>{{ response_result.linkedToMobileApplication ? 'Да' : 'Нет' }}</td>
                  </tr>
                  <tr>
                    <td>Количество карты</td>
                    <td>{{ response_result.cardsAmount }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </template>


      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import PageMixins from "@/mixins/page-mixin";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "Index",
  mixins: [PageMixins],
  data() {
    return {
      toggleImport: false,
      filter: {
        school: '',
        class: '',
        iin: '',
        only_iin: 0,
      },
      all_schools: {},
      all_class: {},
      iin: '',
      school_name: '',
      school_class: '',
      file: {},
      response_result: {},
      cards: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    this.axios.get('/check-pupil-by-iin/helper')
        .then(response => {
          this.all_schools = response.data
        });
    this.getResults(1);
  },
  methods: {
    parseData(data) {
      let mgp = JSON.parse(data);
      return 'Имееться ли карта: ' + (mgp.hasCards ? 'Да' : 'Нет') + '<br>' +
          'Привязана ли к МП: ' + (mgp.linkedToMobileApplication ? 'Да' : 'Нет') + '<br>' +
          'Количество карты: ' + (mgp.cardsAmount ? mgp.cardsAmount : '')
    },
    onFileChange(event) {
      this.file = event.target.files[0]
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    uploadFile() {
      event.preventDefault()
      const app = this
      app.isLoading = true
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      const formData = new FormData()
      formData.append('file', app.file)
      formData.append('school_name', app.school_name)
      formData.append('school_class', app.school_class)
      this.axios.post('/check-pupil-by-iin/upload', formData, config).then(function (response) {
        console.log(response);
        app.snackbar = true;
        app.snackbarColor = "green";
        app.snackbarText = "Успешно добавлено";
        app.school_name = ''
        app.school_class = ''
        app.file = '';
        document.getElementById("myFile").value = "";
        app.getResults(1);
      }).catch(function (error) {
        console.log(error);
      })
    },
    checkData(index, item) {
      let app = this;
      this.axios.get('/check-pupil-by-iin/' + item.iin)
          .then(response => {
            app.cards.data[index].response = response.data;
          });
    },
    getResults(page) {
      let app = this;
      let params = {
        'page': page,
        'filter': this.filter
      };
      if (app.filter.only_iin == 1) {
        this.axios.get('/check-pupil-by-iin/' + app.filter.iin, {params: params})
            .then(response => {
              app.response_result = JSON.parse(response.data);
              console.log(app.response_result);
            });
      } else {
        this.axios.get('/check-pupil-by-iin', {params: params})
            .then(response => {
              this.cards = response.data
            });
      }

    }
  }, components: {
    'Pagination': LaravelVuePagination
  },
};
</script>
<style lang="scss">
.q {
  &__select {
    padding: 5px 15px;
    border: 1px solid #000;
    width: 100%;
    margin-bottom: 20px;
  }

}
</style>

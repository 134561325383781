<template>
  <v-row>
    <v-col>
      <h2>Добавить блюдо</h2>
      <v-form
          ref="form"
          lazy-validation
      >
        <v-select
            v-if="!user.city_id"
            v-model="dish.city_id"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Выберите город"
            :rules='[(v) => !!v || "Поле \"Город\"  обязательно"]'
            clearable
            @click="selectCity"
        />
        <v-alert
            v-if="errorsMessages.city_id"
            dense
            outlined
            type="error"
        >
          {{ errorsMessages.city_id[0] }}
        </v-alert>

        <v-text-field
            v-model.trim="dish.name"
            label="Название*"
            :rules='[(v) => !!v || "Поле \"Название\"  обязательно"]'
            @input="inputName"
        />
        <v-alert
            v-if="errorsMessages.name"
            dense
            outlined
            type="error"
        >
          {{ errorsMessages.name[0] }}
        </v-alert>

        <v-text-field
            v-model.trim="dish.article"
            label="Артикул"
            :rules='[(v) => !!v || "Поле \"Артикул\"  обязательно"]'
            @input="inputArticle"
        />
        <v-alert
            v-if="errorsMessages.article"
            dense
            outlined
            type="error"
        >
          {{ errorsMessages.article[0] }}
        </v-alert>

        <v-text-field
            v-model.trim="dish.price_cash"
            label="Цена наличными"
            required
            :rules='[(v) => /^[+-]?\d+(\.\d+)?$/.test(v) || !v || "Поле \"Цена наличными\" должен быть числом "]'
            @input="inputPriceCash"
        />

        <v-alert
            v-if="errorsMessages.price_cash"
            dense
            outlined
            type="error"
        >
          {{ errorsMessages.price_cash[0] }}
        </v-alert>
        <v-text-field
            v-model.trim="dish.price_cashless"
            label="Цена безналичная"
            required
            :rules='[(v) => /^[+-]?\d+(\.\d+)?$/.test(v) || !v || "Поле \"Цена безналичная\" должен быть числом "]'
            @input="inputPriceCashless"
        />
        <v-alert
            v-if="errorsMessages.price_cashless"
            dense
            outlined
            type="error"
        >
          {{ errorsMessages.price_cashless[0] }}
        </v-alert>

        <v-textarea
            v-model.trim="dish.description"
            label="Описание"
            required
        />
        <v-textarea
            v-model.trim="dish.other_info"
            label="Дополнительная информация"
        />
        <div
            class="file-dragable"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
        >
          <v-file-input
              truncate-length="15"
              label="Выберите фото или перетащите"
              accept="image/*"
              v-model="uploadFile"
              ref="file"
              prepend-icon="mdi-camera"
          ></v-file-input>
          <v-alert
              v-if="errorsMessages.image"
              dense
              outlined
              type="error"
          >
            {{ errorsMessages.image[0] }}
          </v-alert>

        </div>

        <v-snackbar v-model="snackbar">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
                :color="snackbarColor"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-btn
            @click="save"
            class="ma-2"
            outlined
            color="indigo"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-col>

  </v-row>
</template>

<script>
import FormMixin from "@/mixins/form-mixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      menuDate: false,
      dish: {
        name: null,
        article: null,
        price_cash: 0,
        price_cashless: 0,
        description: null,
        other_info: null,
        city_id: null,
        image: null,
      },
      errorsMessages: {
        name: null,
        article: null,
        price_cash: null,
        price_cashless: null,
        city_id: null,
        image: null,
      },
      uploadFile: null,
      selectedCityId: null,
      cities: [],
      user: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.dish.city_id = this.user.city_id
    this.loadCities();
  },

  methods: {
    selectCity() {
      this.loadCities();
    },
    loadCities() {
      this.axios("/cities").then((responseCities) => {
        this.cities = responseCities.data.data.cities;
      });
    },
    inputName() {
      this.errorsMessages.name = null;
    },
    inputArticle() {
      this.errorsMessages.article = null;
    },
    inputPriceCash() {
      this.errorsMessages.price_cash = null;
    },
    inputPriceCashless() {
      this.errorsMessages.price_cashless = null;
    },

    startSelectCity() {
      this.loadCities();
    },
    onChange() {
      console.log(this.$refs.file.files);
      this.uploadFile = this.$refs.file.files[0];
      this.errorsMessages.image = null;
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },

    getFormData() {
      const formData = new FormData();
      if (this.uploadFile) {
        formData.append("image", this.uploadFile);
      }
      for (const [key, value] of Object.entries(this.dish)) {
        if (key !== "image") {
          formData.append(key, value);
        }
      }
      return formData;
    },
    save() {
      this.valid = this.$refs.form.validate();
      const formData = this.getFormData();

      if (this.valid) {
        this.axios
            .post("/dishes", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({status}) => {
              if (status === 200) {
                this.snackbar = true;
                this.snackbarColor = "green";
                this.snackbarText = "Успешно добавлено";
                this.$refs.form.reset();
              }
            })
            .catch(({response}) => {
              if (response.status === 422) {
                this.snackbar = true;
                this.snackbarText = "Данные заполненые неправильно"; //Object.entries(response.data.errors)[0];
                this.errorsMessages = response.data.errors;
              } else {
                this.snackbar = true;
                this.snackbarText = response.data.data.message;
              }
            });
      }
    },
  },
  watch: {
    selectedCityId: {
      handler(id) {
        this.dish.city_id = id;
      },
    },
  },
};
</script>

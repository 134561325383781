<template>
  <v-app>
    <v-app-bar
        v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl"
        color="cyan darken-4"
        dark
        app
    >
      <!-- <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear> -->

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
    </v-app-bar>
    <v-navigation-drawer
        v-if="token"
        v-model="drawer"
        app
    >
      <v-list-item>
        <v-list-item-content>
          <router-link :to="{name:'Index'}" class="logo__link">
            <v-list-item-title class="title">
              {{ appName }}
            </v-list-item-title>
          </router-link>

          <v-list-item-subtitle>Панель управления</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn
            v-if="canCreate('1')"
            small
            fab
            class="primary"
            @click="isShowDialog = true"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-list-item>

      <v-divider/>

      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.route"
        >
          <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-progress-linear
            :active="isLoading"
            :indeterminate="isLoading"
            absolute
            top
            color="deep-purple accent-4"
        ></v-progress-linear>
        <v-row justify="center">

          <v-dialog
              v-model="isShowDialog"
              max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
                Изменить название
              </v-card-title>

              <v-card-text>
                <v-text-field
                    v-model.trim="appName"
                    :loading="isLoading"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click="isShowDialog = false"
                >
                  Закрыть
                </v-btn>

                <v-btn
                    class="primary"
                    text
                    @click="saveSiteName"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <router-view/>
        <v-snackbar v-model="snackbar">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
                :color="snackbarColor"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState, mapActions} from "vuex";

export default {
  name: "App",
  mixins: [PageMixins],
  data: () => ({
    drawer: false,
    isShowDialog: false,
    appName: "",
    user: '',
    // isLoading: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      isLoading: (state) => state.isLoading,
    }),
    items() {
      const sidebar = [
        {
          title: "Пользователи",
          route: "/admin/users",
          permission: {name: "1"},
        },
        {
          title: "Города",
          route: "/admin/cities",
          permission: {name: "1"},
        },
        {
          title: "Дорога в школу",
          route: "/admin/road-to-school",
          permission: {name: "1"},
        },
        {
          title: "Организации",
          route: "/admin/caterings",
          permission: {name: "1"},
        },
        {
          title: "Столовые",
          route: "/admin/canteens",
          permission: {name: "1"},
        },
        {
          title: "Типы документов",
          route: "/admin/canteen-document-types",
          permission: {name: "1"},
        },
        {
          title: "Документы",
          route: "/admin/canteen-documents",
          permission: {name: "7"},
        },
        {
          title: "Комплексные категории",
          route: "/admin/complex-categories",
          permission: {name: "1"},
        },
        {
          title: "Меню города",
          route: "/admin/dishes",
          permission: {name: "9"},
        },
        {
          title: "Меню своей организации",
          route: "/admin/dishes-own",
          permission: {name: "2"},
        },
        {
          title: "Недельное меню",
          route: "/admin/weekly-canteen-dishes",
          permission: {name: "4"},
        },
        {
          title: "Недельное меню школы",
          route: "/admin/weekly-school-dishes",
          permission: {name: "6"},
        },
        {
          title: "Комплексное меню",
          route: "/admin/complex-dishes",
          permission: {name: "2"},
        },
        {
          title: "Отчеты на согласование",
          route: "/admin/my-reports/",
          permission: {name: "6"},
        },
        {
          title: "Отчеты на согласование #1",
          route: "/admin/all-statistics/",
          permission: {name: "1"},
        },
        {
          title: "Проверка карты",
          route: "/admin/card-checker/",
          permission: {name: "1"},
        },
        // {
        //   title: "Отчеты",
        //   route: "/admin/statistics",
        //   permission: { name: "2" },
        // },
      ];
      const permissions = this.$store.state.currentUser.user.permissions;
      console.log(permissions);
      let permissionsNames = [];

      permissions.forEach((p) => {
        permissionsNames.push(p.name);
      });

      const sideBarByPermission = sidebar.filter((item) => {
        return permissionsNames.includes(item.permission.name);
      });

      sideBarByPermission.push({
        title: "Отчеты",
        route: "/admin/statistics",
      });
      sideBarByPermission.push({
        title: "Выйти",
        route: "/admin/logout",
      });
      return sideBarByPermission;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.$router.currentRoute.name !== "Login") {
      this.saveUser(JSON.parse(localStorage.getItem("user")));
      this.saveToken(localStorage.getItem("token"));
      this.saveRefreshToken(localStorage.getItem("refresh_token"));
    }
  },
  mounted() {
    if (document.querySelector("body").offsetWidth > 1200) {
      this.drawer = true;
    }
    if (this.token) {
      this.getAppSettings();
    }
  },
  methods: {
    ...mapActions(["saveToken", "saveUser", "saveRefreshToken"]),
    getAppSettings() {
      // console.log(this.$store.state.auth.token, "============", this.token);
      this.axios
          .get("/app-settings", {
            headers: {Authorization: `Bearer ${this.$store.state.auth.token}`},
          })
          .then((resp) => {
            console.log(resp);
            this.appName = resp.data.data.app_settings[0].settings.app_name;
          });
    },
    saveSiteName() {
      // this.isLoading = true;
      this.axios
          .post(
              "/app-settings",
              {settings: {app_name: this.appName}},
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.auth.token}`,
                },
              }
          )
          .then((resp) => {
            console.log(resp.data.data.app_settings.settings.app_name);
            this.isShowDialog = false;
            // this.options = resp.data.app_settings;
            this.appName = resp.data.data.app_settings.settings.app_name;
            localStorage.setItem("app_name", this.appName);
          })
          .catch((err) => {
            // this.isLoading = false;
            console.log("ERROR", err.response);
            this.snackbar = true;
            this.snackbarText = err.response.data.errors;
          });
    },
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 960px) {
  .row-reverse {
    flex-direction: column-reverse;
  }
}

.v-main__wrap {
  background: #fff;
}

.logo__link {
  text-decoration: none;
  color: #000 !important;
}
</style>

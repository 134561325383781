import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      axios: null,
    };
  },
  created() {
    this.createAxios();
    createAuthRefreshInterceptor(this.axios, this.refreshAuthLogic);
    // console.log("AXIOS STATE", this.$store.state);
  },
  methods: {
    ...mapActions(["saveToken", "saveRefreshToken"]),
    refreshAuthLogic(failedRequest) {
      console.log("FAILED REUSET", failedRequest);
      return this.axios
        .post("/refresh-access-token", {
          refresh_token: this.$store.state.auth.refreshToken,
        })
        .then((tokenRefreshResponse) => {
          localStorage.setItem(
            "token",
            tokenRefreshResponse.data.data.access_token
          );
          localStorage.setItem(
            "refresh_token",
            tokenRefreshResponse.data.data.refresh_token
          );
          this.saveToken(localStorage.getItem("token"));
          this.saveRefreshToken(localStorage.getItem("refresh_token"));
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + this.$store.state.auth.token;
          //   console.log(Promise.resolve());
          return Promise.resolve();
        })
        .catch(() => {
          //   console.log("FROM request", err);
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("refresh_token");
          window.localStorage.removeItem("user");
          window.location = "/admin/login";
        });
    },

    createAxios() {
      this.axios = axios.create({
        // baseURL: "https://sm-api.tolem.asia/api",
        baseURL: process.env.VUE_APP_URL,
      });
      this.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.state.auth.token}`;
    },
  },
};

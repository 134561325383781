<template>
  <v-row>
    <v-col>
      <v-btn
        class="primary btn-new-city"
        to="/admin/caterings/create"
        v-if="canCreate('1')"
      >
        Добавить
      </v-btn>
      <v-spacer />
      <v-select
        v-model="defaultSelectedCity"
        :items="cities"
        item-text="name"
        item-value="id"
        label="Выберите город"
        return-object
      />

      <caterings-list
        v-if="defaultSelectedCity"
        :headers="headers"
        :items="caterings"
        @rowClick="selectCatering"
      />
      <template v-if="paginationLen">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="paginationLen"
          ></v-pagination>
        </div>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isShowMore"
            persistent
            max-width="600px"
            @click:outside="isShowMore=false"
          >
            <v-card v-if="selectedCatering">
              <v-card-title>
                <span class="text-h5">{{selectedCatering.name}}</span>
              </v-card-title>
              <v-card-text>
                <v-row
                  justify="center"
                  v-if="isLoading"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
                <v-container v-else>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>ID: {{selectedCatering.id}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Имя: {{selectedCatering.name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>БИН: {{selectedCatering.bin}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>IBAN: {{selectedCatering.iban}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>БИК: {{selectedCatering.bik}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Банк: {{selectedCatering.bank_name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Имя директора: {{selectedCatering.director_full_name}} </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <p>Дополнительная информация: {{selectedCatering.other_info}} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="isShowMore = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>

  </v-row>

</template>

<script>
import CateringsList from "@/components/UI/List";
import PageMixins from "@/mixins/page-mixin";

export default {
  name: "Page",
  components: { CateringsList },
  mixins: [PageMixins],
  data() {
    return {
      isError: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "BIN", value: "bin" },
        { text: "Название", value: "name" },
        { text: "Полное имя директора", value: "director_full_name" },
        { text: "БИК", value: "bik" },
        { text: "IBAN", value: "iban" },
        { text: "Телфон", value: "phones" },
      ],
      caterings: [],
      cities: [],
      defaultSelectedCity: null,
      selectedCatering: null,
      isShowMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getAllCites();
  },

  methods: {
    selectCatering(catering) {
      this.isShowMore = true;
      this.isLoading = true;
      this.axios(`/caterings/${catering?.id}`).then((resp) => {
        this.selectedCatering = resp.data.data.catering;
        this.isLoading = false;
      });
    },
    getAllCaterings() {
      const vm = this;
      this.axios
        .get(
          `/caterings?city_id=${this.defaultSelectedCity.id}&per_page=${this.perPage}&page=${this.page}`
        )
        .then((resp) => {
          vm.caterings = resp.data.data.caterings.data;
          vm.totalPage = resp.data.data.caterings.total;
        });
    },
    getAllCites() {
      const vm = this;
      this.axios
        .get(`/cities?per_page=10000&page=${this.page}`)
        .then(function (resp) {
          vm.cities = resp.data.data.cities.data;
          window.sessionStorage.setItem("cities", JSON.stringify(vm.cities));
        });
    },
  },
  watch: {
    defaultSelectedCity: {
      handler() {
        this.getAllCaterings();
      },
    },
    page: {
      handler() {
        this.getAllCaterings();
      },
    },
    caterings: {
      handler() {
        this.$nextTick(() => {
          this.paginationLen = Math.ceil(this.totalPage / this.perPage);
        });
      },
    },
  },
};
</script>

<style scoped>
</style>

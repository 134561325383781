<template>
  <div class="index-page">
    <div class="col-md-12">
      <div class="mt-4">
        <router-link to="/admin/road-to-school"
                     class="primary btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          Список детей
        </router-link>
        <router-link to="/admin/road-to-school/schools"
                     class="mx-2 primary btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          Школы
        </router-link>
        <router-link to="/admin/road-to-school/companies"
                     class="success btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          ТОО или ИП
        </router-link>
      </div>
      <div class="row d-flex align-center">
        <div class="col-md-3">
          <v-select
              :items="companies"
              label="Выбрать компанию"
              persistent-hint
              return-object
              single-line
              v-model="company"
          ></v-select>
        </div>
        <div class="col-md-1">
          <v-btn v-on:click="getResults"
          >Поиск
          </v-btn>
        </div>
      </div>
      <div>
        <v-simple-table class="" light="true">
          <template v-slot:default>
            <thead>
            <tr>
              <th colspan="6" class="text-center">{{ company_data.name }}</th>
            </tr>
            <tr>
              <th colspan="6">БИН {{ company_data ? company_data.bin : '' }}</th>
            </tr>
            <tr>
              <th colspan="5">Итого</th>
              <th>{{ summa }}</th>
            </tr>
            <tr>
              <th class="text-left">
                №
              </th>
              <th class="text-left">
                Номер карты
              </th>
              <th class="text-left">
                ИИН
              </th>
              <th class="text-left">
                ФИО
              </th>
              <th class="text-left">
                Школа
              </th>
              <th class="text-left">
                Сумма
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item,index) in orders"
                :key="index"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.student_card_number }}</td>
              <td>{{ item.student_iin }}</td>
              <td>{{ item.student_name }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.sum }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState} from "vuex";

export default {
  name: "Index",
  mixins: [PageMixins],
  data() {
    return {
      orders: '',
      company: '',
      company_data: '',
      companies: '',
      child_q: '',
      summa: 0,
    }
  },
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    this.axios.get('/road-order/helper')
        .then(response => {
          this.schools = response.data.schools
          this.companies = response.data.companies
        });
  },
  methods: {
    getResults() {
      let params = {
        'q': this.child_q,
        'company': this.company,
      };
      let app = this;
      this.axios.get('/road-order', {params: params})
          .then(response => {
            this.orders = response.data.data.orders;
            this.orders.forEach(function (element) {
              app.summa += element.sum;
            });
          });
      this.axios.get('/road-company?company_name=' + this.company)
          .then(response => {
            this.company_data = response.data;
          });
    }
  },
};
</script>
<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:not(:last-child) > th:not(.v-data-table__mobile-row) ,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead >th {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>

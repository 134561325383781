<template>
  <div class="index-page">
    <div class="col-md-12">
      <div class="mt-4">
        <router-link to="/admin/road-to-school"
                     class="success btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          Список детей
        </router-link>
        <router-link to="/admin/road-to-school/schools"
                     class="mx-2 primary  btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          Школы
        </router-link>
        <router-link to="/admin/road-to-school/companies"
                     class="primary btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">
          ТОО или ИП
        </router-link>
      </div>
      <div>
        <div class="row d-flex align-center">
          <div class="col-md-3">
            <v-text-field
                v-model="child_q"
                label="Поиск"
                required
            ></v-text-field>
          </div>
          <div class="col-md-1">
            <v-btn v-on:click="getResults"
            >Поиск
            </v-btn>
          </div>
        </div>
        <div>
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  №
                </th>
                <th class="text-left">
                  Номер карты
                </th>
                <th class="text-left">
                  ИИН
                </th>
                <th class="text-left">
                  ФИО
                </th>
                <th class="text-left">
                  Школа
                </th>
                <th class="text-left">
                  ТОО
                </th>
                <th class="text-left">
                  Продукт
                </th>
                <th class="text-left">
                  Сумма
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in orders.data"
                  :key="index"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.student_card_number }}</td>
                <td>{{ item.student_iin }}</td>
                <td>{{ item.student_name }}</td>
                <td>{{ item.company }}</td>
                <td>{{ item.school }}</td>
                <td>{{ item.products }}</td>
                <td>{{ item.sum }}</td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <Pagination :data="orders" @pagination-change-page="getResults" :limit="3" show-disabled/>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="col-md-3">
        <input
            type="file"
            class="form-control"
            @change="onFileChange"
        >
        <v-btn v-on:click="uploadFile"
        >Поиск
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import PageMixins from "@/mixins/page-mixin";
import LaravelVuePagination from "laravel-vue-pagination";
export default {
  name: "Index",
  mixins: [PageMixins],
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  data() {
    return {
      file: {},
      imageData: {},
      tab: 1,
      select: {state: 'Florida', abbr: 'FL'},
      schools: [],
      companies: [],
      orders: '',
      child_q: '',
    }
  },
  created() {
    this.getResults(1);
  },
  methods: {
    getResults(page = 1) {
      let params = {
        'q': this.child_q,
        'page': page,
      };
      this.axios.get('/road-order', {params: params})
          .then(response => {
            this.orders = response.data.data.orders
          });
    },
    onFileChange(event) {
      this.file = event.target.files[0]
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    uploadFile() {
      event.preventDefault()
      const app = this
      app.isLoading = true
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      const formData = new FormData()
      formData.append('file', app.file)
      this.axios.post('/road-order/upload', formData, config).then(function (response) {
        console.log(response);
      }).catch(function (error) {
        console.log(error);
      })
    }


  },
  components: {
    'Pagination': LaravelVuePagination
  },
};
</script>
<style>
.pagination {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .sr-only {
  display: none;
}

.pagination li {
  list-style: none;
  margin: 0 16px;
}

.disabled {
  pointer-events: none;
}

.pagination .prev a {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.pagination li a {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  color: #646261 !important;
}

.pagination li {
  list-style: none;
  margin: 0 16px;
}
</style>

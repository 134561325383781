<template>
  <div class="index-page">
    <div class="col-md-12">
      <div class="mt-4">
        <router-link to="/admin/road-to-school" class="primary btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">Список детей</router-link>
        <router-link to="/admin/road-to-school/schools" class="mx-2 success btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">Школы</router-link>
        <router-link to="/admin/road-to-school/companies" class="primary btn-new-city v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default">ТОО или ИП</router-link>
      </div>
        <div class="row d-flex align-center">
          <div class="col-md-3">
            <v-select
                :items="schools"
                label="Выбрать школу"
                persistent-hint
                return-object
                single-line
                v-model="school"
            ></v-select>
          </div>
          <div class="col-md-1">
            <v-btn v-on:click="getResults"
            >Поиск
            </v-btn>
          </div>
        </div>
        <div>
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  №
                </th>
                <th class="text-left">
                  Номер карты
                </th>
                <th class="text-left">
                  ИИН
                </th>
                <th class="text-left">
                  ФИО
                </th>
                <th class="text-left">
                  Школа
                </th>
                <th class="text-left">
                  ТОО
                </th>
                <th class="text-left">
                  Продукт
                </th>
                <th class="text-left">
                  Сумма
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item,index) in orders.data"
                  :key="index"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.student_card_number }}</td>
                <td>{{ item.student_iin }}</td>
                <td>{{ item.student_name }}</td>
                <td>{{ item.company }}</td>
                <td>{{ item.school }}</td>
                <td>{{ item.products }}</td>
                <td>{{ item.sum }}</td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <Pagination :data="orders" @pagination-change-page="getResults" :limit="3" show-disabled/>

            <!--          <pagination :data="orders" @pagination-change-page="getResults" :limit="3" show-disabled></pagination>-->
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import PageMixins from "@/mixins/page-mixin";
import {mapState} from "vuex";
import LaravelVuePagination from 'laravel-vue-pagination';

export default {
  name: "Index",
  mixins: [PageMixins],
  data() {
    return {
      orders: '',
      school: '',
      schools: '',
      child_q: '',
    }
  },
  components: {
    'Pagination': LaravelVuePagination
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    this.axios.get('/road-order/helper')
        .then(response => {
          this.schools = response.data.schools
          this.companies = response.data.companies
        });
    this.getResults(1);
  },
  methods: {
    getResults(page = 1) {
      let params = {
        'q': this.child_q,
        'page': page,
        'school': this.school,
      };
      this.axios.get('/road-order', {params: params})
          .then(response => {
            this.orders = response.data.data.orders
          });
    }
  },
};
</script>
